import axios from "axios"

const API_URL = {
    LOGIN: "api/auth/login",
    REFRESH: "api/employee/refresh",
    // LOGOUT: "api/employee/logout",
    GET_UNIT: "api/unit/type",
    MANUFACTURER:"api/manufacturer",

    MATERIAL: "api/material",
    MATERIAL_LIST: "api/material/sampleList",

    PACK_CLASS: "api/pack/packClass",
    PACK: "api/pack",
    PACK_LIST: "api/pack/sampleList",

    CONTENT: "api/content",

    SINGLE: "api/single",
    SINGLE_LIST: "api/single/sampleList",

    GIFT: "api/gift",
    GIFT_LIST: "api/gift/sampleList",

    PURCHASE: "api/purchase/",
    WAREHOUSE: "api/position/add/",
    POSITION_HISTORY: "api/position/historyPurchase",
    POSITION: "api/position/purchase",
    POSITION_LIST: "api/position",
    PICK: "api/pick",
    PROCESS: "api/process",
    PROCESS_STATUS_DETAIL: "api/process/product/packDetail",
    PROCESS_DETAIL: "api/process/product",
    PROCESS_FINISH: "api/process/product/finish",
    PROCESS_TEMPORARY: "api/process/product/item",
    PROCESS_PACK_TEMPORARY: "api/process/product/temporary",

    TEMPORARY:"api/temporary",
    TEMPORARY_RECORD: "api/temporary/history",
    TEMPORARY_TAKE_OUT:"api/temporary/takeOut",
    TEMPORARY_SAVE_BACK:"api/temporary/saveBack",

    OVERAGE:"api/overage",
    UNIT:"api/unit/transform",
    UNIT_LIST:"api/unit/transform/sampleList",
    PRODUCT_LIST: "api/purchase/product/sampleList",
    PRODUCT_DETAIL: "api/purchase/product",
    SHIPMENT: "api/shipment",
    SHIPMENT_DETAIL: "api/shipment/detail",
    POSITION_OVERAGE: "api/position/overage",
    POSITION_PRODUCTION:"api/position/production",
    INVENTORY:"api/position/inventory",
    INVENTORY_RECORD: "api/position/inventory",

    STOCKOUT: "api/stockOut",
    RESTOCK: "api/position/status",

    IMAGE: "api/image",
    ROLE: "api/role",
    USER: "api/user"
}
const handleApiResponse = (response, defaultTotal = 1) => {
    const responseData = response.data.data ?? [];
    const totalNum = response.data.data?.total ?? defaultTotal;
    const success = response.data.success;
    const message = response.data.msg;
    const load = false
    return { responseData, success, message, totalNum, load };
};
const handleApiError = (errorMessage = '失敗') => {
    return { responseData: null, success: false, message: errorMessage, load: false };
};
const makeApiRequest = async (endpoint, method, payload = null) => {
    try {
      let response;
  
      if (method === 'GET') {
        response = await axios.get(endpoint, { params: payload });
      } else if (method === 'POST') {
        response = await axios.post(endpoint, payload);
      } else if (method === 'PUT') {
        response = await axios.put(endpoint, payload);
      } else if (method === 'DELETE') {
        response = await axios.delete(endpoint);
      }
  
      return handleApiResponse(response);
    } catch {
      return handleApiError();
    }
  };
export const userApi = async ({payload}) => {
    const endpoint = API_URL.USER;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const userSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.USER}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');
}
export const userAddApi = async ({payload}) => {
    const endpoint = API_URL.USER;
    return makeApiRequest(endpoint, 'POST', payload);
}
export const userEditApi = async ({payload}) => {
    const endpoint = `${API_URL.USER}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const userDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.USER}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE', payload);  
}
export const roleApi = async ({payload}) => {
    const endpoint = API_URL.ROLE;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const roleListApi = async () => {
    const endpoint = `${API_URL.ROLE}/sampleList`;
    return makeApiRequest(endpoint, 'GET');
}
export const roleSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.ROLE}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const roleAddApi = async ({payload}) => {
    const endpoint = API_URL.ROLE;
    return makeApiRequest(endpoint, 'POST', payload);
}
export const roleEditApi = async ({payload}) => {
    const endpoint = `${API_URL.ROLE}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload);   
}
export const roleDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.ROLE}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE', payload);   
}
export const imageApi = async ({image}) => {
    const endpoint = API_URL.IMAGE;
    return makeApiRequest(endpoint, 'POST', image);
}
export const loginApi = async ({payload}) => {
    const endpoint = API_URL.LOGIN;
    return makeApiRequest(endpoint, 'POST', payload);
}
// export const refreshApi = async() => {
//     const response = await axios.post(API_URL.REFRESH)
//     console.log('response', response)
// }
export const getUnitApi = async (type) => {
    const endpoint = `${API_URL.GET_UNIT}/${type}`;
    return makeApiRequest(endpoint, 'GET');
}
export const getManufacturer = async () => {
    const endpoint = API_URL.MANUFACTURER;
    return makeApiRequest(endpoint, 'GET');
}
export const manufacturerAddApi = async ({payload}) => {
    const endpoint = API_URL.MANUFACTURER;
    return makeApiRequest(endpoint, 'POST', payload);
}
// export const logoutApi = async ({payload}) => {
//     const response = await axios.post(API_URL.LOGOUT, payload)
//     console.log('登出', response)
// }
export const materialListApi = async () => {
    const endpoint = API_URL.MATERIAL_LIST;
    return makeApiRequest(endpoint, 'GET');
}
export const materialApi = async ({payload}) => {
    const endpoint = API_URL.MATERIAL;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const materialAddApi = async ({payload}) => {
    const endpoint = API_URL.MATERIAL;
    return makeApiRequest(endpoint, 'POST', payload);
}
export const materialEditApi = async ({payload}) => {
    const endpoint = `${API_URL.MATERIAL}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const materialDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.MATERIAL}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE', payload);  
}
export const materialSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.MATERIAL}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');
}
export const packClassApi = async () => {
    const endpoint = API_URL.PACK_CLASS;
    return makeApiRequest(endpoint, 'GET');
}
export const packListApi = async () => {
    const endpoint = API_URL.PACK_LIST;
    return makeApiRequest(endpoint, 'GET');
}
export const packApi = async ({payload}) => {
    const endpoint = API_URL.PACK;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const packAddApi = async ({payload}) => {
    const endpoint = API_URL.PACK;
    return makeApiRequest(endpoint, 'POST', payload);
}
export const packEditApi = async ({payload}) => {
    const endpoint = `${API_URL.PACK}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const packDeleteApi = async ({payload}) => {   
    const endpoint = `${API_URL.PACK}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE'); 
}
export const packSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.PACK}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');
}
export const contentApi = async ({payload}) => {
    const endpoint = API_URL.CONTENT;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const contentAddApi = async ({payload}) => {
    const endpoint = API_URL.CONTENT;
    return makeApiRequest(endpoint, 'POST', payload);  
}
export const contentSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.CONTENT}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const contentDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.CONTENT}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE', payload);
}
export const contentEditApi = async ({payload}) => {
    const endpoint = `${API_URL.CONTENT}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload);
}

export const singleApi = async ({payload}) => {
    const endpoint = API_URL.SINGLE;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const singleAddApi = async ({payload}) => {
    const endpoint = API_URL.SINGLE;
    return makeApiRequest(endpoint, 'POST', payload);  
}
export const singleEditApi = async ({payload}) => {
    const endpoint = `${API_URL.SINGLE}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload);   
}
export const singleSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.SINGLE}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');
}
export const singleDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.SINGLE}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE'); 
}
export const singleListApi = async () => {
    const endpoint = API_URL.SINGLE_LIST;
    return makeApiRequest(endpoint, 'GET');
}
export const giftApi = async ({payload}) => {
    const endpoint = API_URL.GIFT;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const giftSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.GIFT}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');
}
export const giftAddApi = async ({payload}) => {
    const endpoint = API_URL.GIFT;
    return makeApiRequest(endpoint, 'POST', payload);    
}
export const giftEditApi = async ({payload}) => {
    const endpoint = `${API_URL.GIFT}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const giftDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.GIFT}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE');
}
export const giftListApi = async () => {
    const endpoint = API_URL.GIFT_LIST;
    return makeApiRequest(endpoint, 'GET');
}
export const purchaseApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PURCHASE}${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const purchaseSearchApi = async (type, {payload}) => {
    const endpoint = `${API_URL.PURCHASE}${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const purchaseAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PURCHASE}${type}`;
    return makeApiRequest(endpoint, 'POST', payload);  
}
export const purchaseDeleteApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PURCHASE}${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE', payload); 
}
export const warehouseAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.WAREHOUSE}${type}`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const positionHistoryApi = async (type,{payload}) => {
    const endpoint = `${API_URL.POSITION_HISTORY}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const positionHistorySearchApi = async (type,{payload}) => {
    const endpoint = `${API_URL.POSITION_HISTORY}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const positionApi = async (type,{payload}) => {
    const endpoint = `${API_URL.POSITION}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const positionAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.POSITION}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const positionListApi = async (type) => {
    const endpoint = `${API_URL.POSITION_LIST}/${type}/simpleList`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const pickApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}

export const pickAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const pickEditApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const pickSearchApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const pickDeleteApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE'); 
}
export const pickHistoryApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}/history`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const pickReprintApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PICK}/${type}/reprintDocument/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const pickProcessApi = async (type, {payload}) => {
    const endpoint = `${API_URL.PICK}/${type}/process`;
    return makeApiRequest(endpoint, 'GET', payload);
}
export const pickProcessAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PROCESS}/${type}/pick`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const processAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PROCESS}/${type}`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const processApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PROCESS}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const processSearchApi = async (type,{payload}) => {
    const endpoint = `${API_URL.PROCESS}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const processVoidApi = async ({payload}) => {
    const endpoint = `${API_URL.PROCESS}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE'); 
}
export const processContentApi = async ({payload}) => {
    const endpoint = `${API_URL.PROCESS}/content/material`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const processContentDetailApi = async ({payload}) => {
    const endpoint = `${API_URL.PROCESS}/content/material/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const processPackItemApi = async(type, {payload}) => {
    const endpoint = `${API_URL.PROCESS}/product/${type}Item`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const processPackRecordApi = async(type, {payload}) => {
    const endpoint = `${API_URL.PROCESS}/product/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const processPackStatusApi = async({payload}) => {
    const endpoint = `${API_URL.PROCESS}/product/pack`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const processStatusDetailApi = async({payload}) => {
    const endpoint = `${API_URL.PROCESS_STATUS_DETAIL}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const processDetailApi = async({payload}) => {
    const endpoint = `${API_URL.PROCESS_DETAIL}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const processFinishApi = async ({payload}) => {
    const endpoint = `${API_URL.PROCESS_FINISH}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT'); 
}
export const processTemporarySearchApi = async({payload}) => {
    const endpoint = `${API_URL.PROCESS_TEMPORARY}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const processTemporaryEditApi = async({payload}) => {
    const endpoint = `${API_URL.PROCESS_PACK_TEMPORARY}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const temporaryApi = async({payload}) => {
    const endpoint = `${API_URL.TEMPORARY}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const temporaryTakeOutApi = async({payload}) => {
    const endpoint = `${API_URL.TEMPORARY_TAKE_OUT}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const temporaryRecordApi = async({payload}) => {
    const endpoint = `${API_URL.TEMPORARY_RECORD}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const temporaryBackApi = async({payload}) => {
    const endpoint = `${API_URL.TEMPORARY_SAVE_BACK}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const overageApi = async(type, {payload}) => {
    const endpoint = `${API_URL.OVERAGE}/${type}/pick`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const overageSearchApi = async(type, {payload}) => {
    const endpoint = `${API_URL.OVERAGE}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const overageAddApi = async (type,{payload}) => {
    const endpoint = `${API_URL.OVERAGE}/${type}`;
    return makeApiRequest(endpoint, 'POST', payload);  
}
export const overageRecordApi = async (type,{payload}) => {
    const endpoint = `${API_URL.OVERAGE}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload);  
}
export const overageVoidApi = async(type, {payload}) => {
    const endpoint = `${API_URL.OVERAGE}/${type}/destroy/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT');
}
export const overageReprintApi = async(type, {payload}) => {
    const endpoint = `${API_URL.OVERAGE}/${type}/reprintDocument/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT');
}
export const unitApi = async ({payload}) => {
    const endpoint = `${API_URL.UNIT}`;
    return makeApiRequest(endpoint, 'GET', payload);  
}
export const unitSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.UNIT}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');  
}
export const unitAddApi = async ({payload}) => {
    const endpoint = `${API_URL.UNIT}`;
    return makeApiRequest(endpoint, 'POST', payload);  
}
export const unitDeleteApi = async ({payload}) => {
    const endpoint = `${API_URL.UNIT}/${payload.id}`;
    return makeApiRequest(endpoint, 'DELETE');  
}
export const unitEditApi = async ({payload}) => {
    const endpoint = `${API_URL.UNIT}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload);  
}
export const unitListApi = async () => {
    const endpoint = `${API_URL.UNIT_LIST}`;
    return makeApiRequest(endpoint, 'GET');  
}
export const productListApi = async ({payload}) => {
    const endpoint = `${API_URL.PRODUCT_LIST}`;
    return makeApiRequest(endpoint, 'GET',payload);  
}
export const productSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.PRODUCT_DETAIL}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');  
}
export const shipmentAddApi = async ({payload}) => {
    const endpoint = `${API_URL.SHIPMENT}`;
    return makeApiRequest(endpoint, 'POST', payload);  
}
export const shipmentNumberApi = async ({payload}) => {
    const endpoint = `${API_URL.SHIPMENT}`;
    return makeApiRequest(endpoint, 'GET',payload);  
}
export const shipmentSearchApi = async ({payload}) => {
    const endpoint = `${API_URL.SHIPMENT}/${payload.id}`;
    return makeApiRequest(endpoint, 'GET');  
}
export const shipmentDetailApi = async ({payload}) => {
    const endpoint = `${API_URL.SHIPMENT_DETAIL}`;
    return makeApiRequest(endpoint, 'GET',payload);  
}
export const shipmentVoidApi = async ({payload}) => {
    const endpoint = `${API_URL.SHIPMENT}/destroy/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT');  
}
export const stockOutApi = async (type, {payload}) => {
    const endpoint = `${API_URL.STOCKOUT}/${type}`;
    return makeApiRequest(endpoint, 'GET',payload);  
}
export const stockOutAddApi = async ({payload}) => {
    const endpoint = `${API_URL.STOCKOUT}/${payload.id}`;
    return makeApiRequest(endpoint, 'POST');  
}

export const stockOutRecordApi = async (type, {payload}) => {
    const endpoint = `${API_URL.STOCKOUT}/${type}/history`;
    return makeApiRequest(endpoint, 'GET',payload); 
}
export const stockOutRecordSearchApi = async (type, {payload}) => {
    const endpoint = `${API_URL.STOCKOUT}/${type}/history/${payload.id}`;
    return makeApiRequest(endpoint, 'GET',payload); 
}
export const restockApi = async (type, {payload}) => {
    const endpoint = `${API_URL.RESTOCK}/${type}`;
    return makeApiRequest(endpoint, 'GET',payload); 
}
export const restockSearchApi = async (type, {payload}) => {
    const endpoint = `${API_URL.RESTOCK}/${type}/detail`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const restockSearchDetailApi = async (type, {payload}) => {
    const endpoint = `${API_URL.RESTOCK}/${type}/detail/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const restockAddApi = async (type, {payload}) => {
    const endpoint = `${API_URL.RESTOCK}/${type}/restock`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const restockRecordApi = async (type, {payload}) => {
    const endpoint = `${API_URL.RESTOCK}/${type}/restock`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const restockRecordSearchApi = async (type, {payload}) => {
    const endpoint = `${API_URL.RESTOCK}/${type}/restock/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const positionOverageApi = async (type, {payload}) => {
    const endpoint = `${API_URL.POSITION_OVERAGE}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const positionOverageAddApi = async (type, {payload}) => {
    const endpoint = `${API_URL.POSITION_OVERAGE}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const positionProductionAddApi = async ({payload}) => {
    const endpoint = `${API_URL.POSITION_PRODUCTION}`;
    return makeApiRequest(endpoint, 'POST', payload); 
}
export const positionInventoryApi = async (type, {payload}) => {
    const endpoint = `${API_URL.INVENTORY}/${type}`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const positionInventoryEditApi = async (type, {payload}) => {
    const endpoint = `${API_URL.INVENTORY}/${type}/${payload.id}`;
    return makeApiRequest(endpoint, 'PUT', payload); 
}
export const inventoryRecordApi = async (type, {payload}) => {
    const endpoint = `${API_URL.INVENTORY}/${type}/history`;
    return makeApiRequest(endpoint, 'GET', payload); 
}
export const inventoryRecordDetailApi = async (type, {payload}) => {
    const endpoint = `${API_URL.INVENTORY_RECORD}/${type}/history/${payload.id}`;
    return makeApiRequest(endpoint, 'GET'); 
}
export const positionInventoryPrintApi = async (type, {payload}) => {
    const endpoint = `${API_URL.INVENTORY}/${type}/printDocument`;
    return makeApiRequest(endpoint, 'POST', payload); 
}