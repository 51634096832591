<template>
  <div class="home">
    <img src="../assets/logo/tatung.svg" alt="">  
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
<style scoped>
.home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
img {
  width: 50%;  
  opacity:.5
}
</style>
