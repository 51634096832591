import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'
import setAuthHeader from './axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

if (localStorage.token) {
    setAuthHeader(localStorage.token)
} else {
    setAuthHeader(false)
}

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')


