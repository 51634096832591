<template>
  <div class="full-height">
    <div class="flex-container">
      <router-view v-slot="{ Component }">
        <template v-if="Component">
            <suspense>
              <component :is="Component" />
            </suspense>
        </template>
      </router-view>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router'
export default {
  setup() {
    const { beforeEach, push } = useRouter();
    beforeEach((to) => {
      if (to.meta.requiresAuth && !localStorage.token) {
        push('/login')
        return true
      }
    })
    const debounce = (callback, delay) => {
      let tid;
      return function (...args) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay)
      }
    }
    const _ = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 20)
        super(callback)
      }
    }
  }
}
</script>
<style>
@import url('@/assets/style/style.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 28px;
}

html,
body {
  height: 100%;
  /* 设置html和body高度为100% */
}

a {
  text-decoration: none;
  color: #333333;
}

#app {
  height: 100%;
  color: #333333;
}

.full-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.flex-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}</style>
