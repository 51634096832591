import axios from 'axios'
// import { refreshApi } from './api';

axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.response.use(
    res => { 
        return res
    }, 
    async error => {
        if(error.response.status === 401) {
            // console.log('失敗啦401')
            delete axios.defaults.headers.Authorization

            // const status = await axios.post(refreshApi())
            // if (status === 200) {                
                // axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
                // return axios(error.config)
            // }
        }
        if(error.response.status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            setAuthHeader(false)
            window.alert('登入憑證過期')
            window.location.href = '/login'
        }
        // console.log('失敗啦500', error.response.status)
        return error
    }
)

const setAuthHeader = (token) => {
    if (token) {
        axios.defaults.headers = {
            Authorization: 'Bearer ' + token
        }
    } else {
        delete axios.defaults.headers.Authorization
    }
}
export default setAuthHeader