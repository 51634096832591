import { getManufacturer, getUnitApi, packClassApi } from '@/api'
import { createStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default createStore({
  state: {
    searchKeyword: '',
    weight: [],
    materialUnit:[],
    packUnit:[],
    quantity:[],
    manufacturer: [],
    packClass: [],
    isFetchingPackClass : false,
  },
  getters: {
    getSearchKeyword(state) {
      return state.searchKeyword
    },
    getWeight(state) {
      return state.weight
    },
    getMaterialUnit(state) {
      return state.materialUnit
    },
    getPackUnit(state) {
      return state.packUnit
    },
    getQuantity(state) {
      return state.quantity
    },
    getManufacturer(state) {
      return state.manufacturer
    },
    getPackClass(state) {
      return state.packClass
    },
  },
  mutations: {
    updateSearchKeyword(state, newValue) {
      state.searchKeyword = newValue
    },
    updateWeight(state, newValue) {
      state.weight = newValue
    },
    updateMaterialUnit(state, newValue) {
      state.materialUnit = newValue
    },
    updatePackUnit(state, newValue) {
      state.packUnit = newValue
    },
    updateQuantity(state, newValue) {
      state.quantity = newValue
    },
    updateManufacturer(state, newValue) {      
      state.manufacturer = newValue    
    },
    updatePackClass(state, newValue) {      
      state.packClass = newValue    
    },
    setFetchingPackClass(state, status) {
      state.isFetchingPackClass  = status
    }
  },
  actions: {
    updateSearchKeyword({ commit }, newValue) {
      commit('updateSearchKeyword', newValue)
    },
    async fetchWeight({commit}) {
      try {
        const {responseData, success} = await getUnitApi('weight')
        if(success){
          commit('updateWeight', responseData)
        } else {
          commit('updateWeight', [])
        }
      } catch (error) {
        ElMessage.warning('fetchWeight有錯誤')
      }
    },
    async fetchMaterialUnit({commit}) {
      try {
        const {responseData, success} = await getUnitApi('material')
        if(success){
          commit('updateMaterialUnit', responseData)
        } else {
          console.error('失敗 ')
        }
      } catch (error) {
        console.error(error)
      }      
    },
    async fetchPackUnit({commit}) {
      try {
        const {responseData, success} = await getUnitApi('singleProduct')
        if(success){
          commit('updatePackUnit', responseData)
        } else {
          console.error('失敗 ')
        }
      } catch (error) {
        console.error(error)
      }      
    },
    async fetchManufacturer({commit}) {
      try {
        const {responseData, success} = await getManufacturer()
        if(success){
          commit('updateManufacturer', responseData)
        } else {
          console.error('失敗 ')
        }
      } catch (error) {
        console.error(error)
      }     
    },
    async fetchQuantity({commit}) {
      try {
        const {responseData, success} = await getUnitApi('quantity')
        if(success){
          commit('updateQuantity', responseData)
        } else {
          console.error('失敗 ')
        }
      } catch (error) {
        console.error(error)
      }     
    },
    async fetchPackClass({commit, state}) {
      if (state.isFetchingPackClass) {
        return Promise.resolve();
      }
      commit('setFetchingPackClass', true);
      try {
        const {responseData, success} = await packClassApi()
        if(success){
          commit('updatePackClass', responseData)
        } else {
          console.error('失敗 ')
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  modules: {
  }
})

